import {Component, Inject, ViewChild} from '@angular/core';
import {
    AcDialog,
    AcDialogService,
    AuthorizationService,
    CommonNotifiersService,
    DIALOG_CONFIG,
    DialogConfig,
    GeneralService,
    PromiseService,
    SchemaHelperService
} from 'ac-infra';
import * as _ from 'lodash';
import {MetadataService} from '../../../metadata/metadata.service';
import {OperatorsActionsService} from '../../../system/administration/administration-actions/operators-actions.service';
import {authGroup} from '../../../common/utilities/session-helper.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {OperatorsRestService} from '../../../system/administration/administration-api/operators-rest.service';
import {TenantsRestService} from '../../services/apis/tenants-rest.service';
import {NetworkValidationsService} from '../../services/network-validations.service';
import {OperatorConnectMenuComponent} from '../../../common/components/operator-connect/operator-connect-menu.component';
import {GroupsActions} from '../../services/actions/groups-actions.service';
import {SaveDataToFileService} from '../../../common/utilities/save-data-to-file.service';


@UntilDestroy()
@AcDialog({
    cancelButtonText: 'Close',
    width: 600,
    height: 600,
    id: 'tenant-dialog',
    title: 'Tenant Details',
})
@Component({
    selector: 'network-tenant-dialog',
    templateUrl: './network-tenant-dialog.component.html',
    styleUrls: ['./network-tenant-dialog.component.less']
})
export class NetworkTenantDialogComponent {

    @ViewChild('operatorConnectMenu', {static: false}) operatorConnectMenu: OperatorConnectMenuComponent;

    schema: any;
    tenantAddSchema = require('../../../../schema/post/tenantsprocessor.json');
    tenantEditSchema = require('../../../../schema/put/tenantsprocessor.json');

    tenant: any = {};
    isDefaultOptions = [{value: true, text: 'True'}, {value: false, text: 'False'}];
    tenantOperatorsOption: any;
    generalLicense: any;
    LicenseOrderList;
    isEdit: boolean;

    originalTenantLicenseAllocation;
    originalTenantObject;
    firstOperatorsFetch;

    licenseAllocations: any = {};
    allTenantAdminOperators;
    tenantAdminOperatorsIds;
    tenantOperators;
    operators;
    logoObject: any = {title: 'Tenant Logo', files: [], logoId: -1};
    noLogo = [{name: 'None', id: -1}];
    additionalSettings: any;
    newOperatorId;
    oneLiveMode = this.generalService.serverInfo.oneLiveMode;
    colors;
    errors;

    constructor(private schemaHelperService: SchemaHelperService,
                private tenantsRestService: TenantsRestService,
                private operatorsActionsService: OperatorsActionsService,
                private networkValidationsService: NetworkValidationsService,
                private operatorsRestService: OperatorsRestService,
                public generalService: GeneralService,
                private acDialogService: AcDialogService,
                private groupsActions: GroupsActions,
                private saveDataToFileService: SaveDataToFileService,
                @Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig) {
        this.colors = GeneralService.statusColors;
    }

    ngOnInit() {
        this.isEdit = this.dialogConfig.dialogData.isEdit;
        this.LicenseOrderList = MetadataService.getType('LicenseOrder', true);

        this.dialogConfig.ready = false;

        this.schema = this.dialogConfig.dialogData.isEdit ? _.cloneDeep(this.tenantEditSchema) : _.cloneDeep(this.tenantAddSchema);
        this.licenseAllocations = this.dialogConfig.dialogData.licenseAllocations;
        this.operators = this.dialogConfig.dialogData.operators;
        this.deleteUnnecessaryItems();
        this.originalTenantLicenseAllocation = _.cloneDeep(this.licenseAllocations);
        this.originalTenantObject = _.cloneDeep(this.dialogConfig.dialogData.tenant);

        this.firstOperatorsFetch = true;

        this.allTenantAdminOperators = [{id: -1, name: ''}];

        this.tenant = this.dialogConfig.dialogData.entity;

        if(this.oneLiveMode){
            this.dialogConfig.width = 800;
            this.dialogConfig.height = 800;
        }

        this.additionalSettings = _.cloneDeep(this.tenant);
        this.tenant.logoId = this.tenant.logoId ? this.tenant.logoId : this.noLogo[0].id;
        this.logoObject.logoId = this.tenant.logoId;

        this.generalLicense = this.dialogConfig.dialogData.licenseObj;
        this.tenant.assignedOperators = this.tenant.assignedOperators || [];

        this.updateOperatorLists(this.operators);

        this.dialogConfig.ready = true;

        CommonNotifiersService.finishedDataAndFilteredUpdate$.pipe(untilDestroyed(this)).subscribe(() => {
            if (this.dialogConfig.ready && AuthorizationService.validFor(authGroup.admin)) {
                this.operatorsActionsService.getOperators().then((operators) => {
                    this.updateOperatorLists(operators);
                });
            }
        });
    }

    addNewOperator = () => {
        this.newOperatorId = undefined;

        const tenantDialogCallback = (response) => {
            this.newOperatorId = response.data.id;
        };
        this.operatorsActionsService.addOperator(false, tenantDialogCallback);
    };

    requiredsAuxiliaryForm = () => {
        const result = [];

        if(this.isEdit){
            if(!this.additionalSettings.operatorConnectEnabled && this.tenant.operatorConnectEnabled){// originalOperatorConnectEnabled- this.additionalSettings.operatorConnectEnabled
                result.push('tenant.operatorConnectInfo.appRegistrationClientSecret');
            }
        }

        return result;
    };

    validateAuxiliaryForm = (errors, auxModel, formModel) => {
        this.networkValidationsService.validateNetworkEntityName('tenant', this.additionalSettings.name)(errors, auxModel, formModel);
        this.tenantsRestService.executeValidateAllocAuxiliaryForm({errors, auxModel, formModel});

        if(this.isEdit){
            const snmpV2Profile = this.tenant.snmpProfile?.snmpV2DefaultProfile;
            this.generalService.cleanEmptyValues(snmpV2Profile, true);
            if(snmpV2Profile && Object.getOwnPropertyNames(snmpV2Profile).length === 0){
                delete this.tenant.snmpProfile.snmpV2DefaultProfile;
            }
        }

        if(this.tenant?.operatorConnectInfo?.offers) {

            if(this.tenant?.operatorConnectInfo?.offers.length === 0){
                errors.push(this.schemaHelperService.buildErrorItem(
                    {
                        instancePath: '/tenant/operatorConnectInfo/offersError',
                        inputName: 'tenant.operatorConnectInfo.offersError',
                        message: 'At least one offer is required'
                    }));
            }

            // if (this.tenant.operatorConnectInfo.offers.length === 2 &&
            //     this.tenant.operatorConnectInfo.offers[0].allowed === this.tenant.operatorConnectInfo.offers[1].allowed) {
            //     errors.push(this.schemaHelperService.buildErrorItem(
            //         {
            //             instancePath: '/tenant/operatorConnectInfo/offersError',
            //             inputName: 'tenant.operatorConnectInfo.offersError',
            //             message: 'Offers must have different allowed values'
            //         }));
            // }
        }


        this.errors = errors;

    };

    deleteUnnecessaryItems() {
        delete this.licenseAllocations.licensePoolFeaturesAllocated;
        delete this.licenseAllocations.url;
        delete this.licenseAllocations.id;
    }

    zoomEnabledChanged = () => {
        if(this.tenant.zoomEnabled){
            this.tenant.zoomInfo = {};
        }else{
            delete this.tenant.zoomInfo;
        }
    };

    operatorConnectEnabledChanged = () => {
        const defaultOperatorConnectObject = {offers: [], operatorContacts: [], trustedProviderIssuers: []};
        if(this.tenant.operatorConnectEnabled){
            if(!_.isEqual(defaultOperatorConnectObject, this.tenant.operatorConnectInfo)){
                const defer = PromiseService.defer();
                this.acDialogService.confirm('Disable operator connect will remove all data in this section, are you sure you want to proceed?', {
                    onSubmit: () => {
                        this.tenant.operatorConnectEnabled = false;
                        delete this.tenant.operatorConnectInfo;
                        this.operatorConnectMenu.calculateItemsInTreeNode('all');
                        defer.resolve();
                        return true;
                    },
                    onCancel: () => {
                        defer.reject();
                    }
                });
            }else{
                this.tenant.operatorConnectEnabled = false;
                delete this.tenant.operatorConnectInfo;
                this.operatorConnectMenu.calculateItemsInTreeNode('all');
            }
        }else{
            this.tenant.operatorConnectEnabled = true;
            this.tenant.operatorConnectInfo = defaultOperatorConnectObject;
        }
    };

    private updateOperatorLists = (operators) => {
        this.tenantAdminOperatorsIds = [];
        this.tenantOperators = operators.filter((operator) => !operator.isSystemOperator);

        this.tenantOperatorsOption = this.tenantOperators ? this.tenantOperators.map((item) => ({value: item.id, text: item.name ? item.name : ''})) : [];

        if(this.newOperatorId && this.isEdit) {
            const operatorId = this.newOperatorId;
            this.newOperatorId = undefined;

            const success = (response) => {
                const operator = response.data;
                if(operator.assignedTenants.includes(this.additionalSettings.id)){
                    this.tenant.assignedOperators = [...this.tenant.assignedOperators, operatorId];
                }
            };
            this.operatorsRestService.getOperator(operatorId, success, ()=>{});
        }

        this.tenantAdminOperatorsIds.unshift({id: -1, name: ''});

        _.forOwn(this.tenantOperators, (operator) => {
            ['ADMIN', 'OPERATOR'].includes(operator.securityLevel) && this.tenantAdminOperatorsIds.push(operator);
        });

    };
}



