import {Component, Inject, SkipSelf} from '@angular/core';
import * as _ from 'lodash';
import {AcDialogComponent, AuthorizationService, DIALOG_CONFIG, DialogConfig, GeneralService} from 'ac-infra';
import {authGroup} from '../../../common/utilities/session-helper.service';

@Component({
    selector: 'network-operator-connect-dialog',
    templateUrl: './network-operator-connect-dialog.component.html',
})
export class NetworkOperatorConnectDialogComponent {

    tenant: any = {};
    operatorConnectSchema = require('../../../../schema/put/tenantsprocessor.json');
    spAttachedTenant;
    errors;
    isSystemOperator = AuthorizationService.validFor(authGroup.SYSTEM_OPERATOR);
    isServiceProviderOperatorType = AuthorizationService.validFor(authGroup.TENANT_OPERATOR);

    constructor(@SkipSelf() private acDialogComponent: AcDialogComponent,
                private generalService: GeneralService,
                @Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig)  {}

    ngOnInit() {
        const params = this.dialogConfig.dialogData;
        this.tenant = params.entity;
        this.spAttachedTenant = _.cloneDeep(params.spAttachedTenant);
    }

    validateAuxiliaryForm = (errors, auxModel, formModel) => {
        this.errors = errors;
    };
}





