<div class="flexColumnParent">
    <div class="logo flexItemBiggest">
        <logo-display></logo-display>
    </div>

    <div class="companyLogo" *ngIf="generalService.serverInfo?.branding === null">
        <ac-svg name="audiocodes_logo" width="243"></ac-svg>
    </div>

    <div class="version flexItem">
        Ver. {{generalService.serverInfo?.appVersion}}
    </div>
</div>
