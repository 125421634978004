import {Injectable} from '@angular/core';
import {Actions} from '../../../common/server-actions/actions';
import * as _ from 'lodash';
import {deviceFileDetails} from './devices-actions.service';
import {ConfirmDialogData, dateTimeFormatter, FormatterType} from 'ac-infra';
import {LockDeviceDialogComponent} from '../../dialogs/lock-device-dialog/lock-device-dialog.component';
import {SyncLinksDialogComponent} from '../../dialogs/sync-links-dialog/sync-links-dialog.component';
import {TenantsRestService} from '../apis/tenants-rest.service';
import {DevicesRestService} from '../apis/devices-rest.service';
import {RestResponseFailure, RestResponseSuccess} from '../../../common/server-actions/rest';
import {SoftwareUpgradeDialogComponent} from '../../dialogs/software-upgrade-dialog/software-upgrade-dialog.component';
import {MetadataService} from '../../../metadata/metadata.service';
import {SoftwareManagerRestService} from '../../../system/configuration/configuration-api/software-manager-rest.service';
import {SelectPmProfileDialogComponent} from '../../dialogs/select-pm-profile-dialog/select-pm-profile-dialog.component';
import {PMRestService} from '../../../statistics/services/pm-rest.service';
import {SaveDataToFileService} from '../../../common/utilities/save-data-to-file.service';
import {DeviceSSOActionsService} from './device-SSO-actions.service';

@Injectable({providedIn: 'root'})
export class DevicesExtendedActionsService extends Actions {

    supportedTypes = MetadataService.getType('SupportedTypes');
    listOfDevicesThatCanOpenDevicePage = MetadataService.getType('ListOfDevicesThatCanOpenDevicePage', true);
    mediaDecompositionDevicesList = MetadataService.getType('MediaDecompositionDevices', true);
    FileSaver;

    actionRestMapper = {
        unlockDevice: {type: 'unlock', dialogData: {submitButtonText: 'unlock'}},
        switchover: {type: 'switchover', dialogData: {submitButtonText: 'switchover', title: 'Switchover'}},
        restoreDevice: {
            type: 'restore',
            dialogData: {
                submitButtonText: 'restore',
                title: 'Restore Last Backup',
                messagePostfix: '<br/>Note: Upon configuration package restore, Device will reset.',
                confirmAlternativeText: 'restore configuration file to'
            }
        },
        setDefaults: {
            type: 'setDefaults',
            dialogData: {
                submitButtonText: 'restore',
                title: 'Restore Default Configuration',
                confirmAlternativeText: 'restore default configuration to'
            }
        },
        resetRedundant: {
            type: 'resetRedundant',
            dialogData: {submitButtonText: 'reset redundant', title: 'Reset Redundant'}
        },
        saveConfiguration: {
            type: 'saveConfiguration',
            dialogData: {
                submitButtonText: 'Save Configuration',
                title: 'Save Configuration To Flash',
                confirmAlternativeText: 'save configuration to flash to'
            }
        },
        backupDevice: {
            type: 'backup',
            dialogData: {
                submitButtonText: 'backup',
                title: 'Backup Configuration File',
                confirmAlternativeText: 'backup configuration file to'
            }
        },
    };

    constructor(private tenantsRestService: TenantsRestService,
                private softwareManagerRestService: SoftwareManagerRestService,
                private performanceMeasurementsService: PMRestService,
                private saveDataToFileService: SaveDataToFileService,
                private deviceSSOActions: DeviceSSOActionsService,
                private devicesRestService: DevicesRestService) {
        super({entityName: 'device', entityService: devicesRestService, isWsEntity: true});
        this.FileSaver = require('file-saver');
    }

    downloadFileIntoDevice = (deviceSelection, isDownloadMode = false) => {
        const devices = this.mapDeviceForUpgradeFn(deviceSelection);
        const deviceDetails: deviceFileDetails = this.getNecessaryDetailsFromDevices(devices, isDownloadMode);
        const fileTypeFilterForDownload = {
            values: ['VER_EMS_TYPE', 'CMP_TYPE', 'RMT_TYPE', 'RMS_TYPE'],
            operators: ['!=', '!=', '!=', '!=']
        };

        const fileTypeFilterForUpload = {
            values: ['VER_EMS_TYPE', '\'CMP_TYPE\';\'RMT_TYPE\';\'RMS_TYPE\''],
            operators: ['!=', '=']
        };

        const uniqeDevicesProductTypes = _.uniqBy(devices, 'productType');

        const withSameTenantId = devices.some((device) => device.tenantId === devices[0].tenantId);

        const tenantIds = withSameTenantId ? [-1, devices[0].tenantId] : [-1];// -1 system files

        const softwareManagerFilter: any = {
            filter: {
                productTypes: {
                    operators: uniqeDevicesProductTypes.map(() => '='),
                    values: uniqeDevicesProductTypes.map((device: any) => device.productType)
                },
                fileType: isDownloadMode ? fileTypeFilterForDownload : fileTypeFilterForUpload,
                tenantId: tenantIds
            }
        };

        if (deviceDetails.mcTypeList && deviceDetails.mcTypeList.length === 1 && deviceDetails.mcTypeList[0] === 'MT') {
            // MT allows upgrade of multiple devices only if there product types are the same
            if (softwareManagerFilter.filter.productTypes.values.length === 1) {// all devices are mcType MT and with the same product type
                softwareManagerFilter.filter.productTypes = softwareManagerFilter.filter.productTypes.values;// filter: values(AND - , between elements), without values(OR - ; between elements)
                softwareManagerFilter.filter.productTypes.push('MEDIANT_4000_ESBC'); // according to genna this bring all MT family files
                // conclusion - MT type will bring all files with familyType(SBC) and all files with familyType(MEDIANT) has to be OR between them
            } else {
                softwareManagerFilter.filter.productTypes.values.push('MEDIANT_4000_ESBC');// will always bring empty file list
            }
        }

        const success = (value) => {
            this.openSoftwareUpgradeDialog(this.tenantsRestService.getAllEntities(), value.files, devices, isDownloadMode, deviceDetails);
        };

        this.getSoftwareFileList(success, () => {
        }, softwareManagerFilter);
    };

    stopUpgradeMC = (deviceSelection) => {
        const entitiesArray = this.mapDeviceForUpgradeFn(deviceSelection);
        this.devicesRestService.doRestAction(() => null, () => null, entitiesArray, undefined, 'stopUpgradeMC');
    };

    resetDevice = (deviceSelection) => {
        const entitiesArray = this.mapDeviceFn(deviceSelection);
        let disableCheckBoxInConfirmDialog = false;
        _.forOwn(entitiesArray, (device) => {
            if (this.listOfDevicesThatCanOpenDevicePage.includes(device.productType)) {
                disableCheckBoxInConfirmDialog = true;
            }
        });
        const options = {
            checkboxModel: {value: true, label: 'Save configuration to flash memory'}
        };
        const serverCallback = (onSuccess, onFailure) => {
            const burnIntoFlash = !!(options.checkboxModel && options.checkboxModel.value);
            const resetActionObject = {gracefulTimeout: 0, burnToFlash: burnIntoFlash ? 'yes' : 'no'};
            this.devicesRestService.doRestAction(onSuccess, onFailure, entitiesArray, resetActionObject, 'reset');
        };

        const dialogData: ConfirmDialogData = {entitiesArray, options, disableModel: disableCheckBoxInConfirmDialog};

        this.genericConfirmAction({
            serverCallback,
            dialogData,
            dialogConfig: {title: 'Reset Device', submitButtonText: 'reset'}
        });
    };

    lockDevices = (deviceSelection) => {
        const entitiesArray = this.mapDeviceFn(deviceSelection);
        const confirmMsg = this.messagesService.getConfirmMessage({
            entityName: 'device',
            entitiesArray,
            actionName: 'lock'
        });

        const dialogData = {actionName: 'lock', entitiesArray, confirmMsg};

        const serverCallback = (onSuccess, onFailure) => {
            const parameters = {gracefulTimeout: this.getGracefulTimeout(dialogData)};
            this.devicesRestService.doRestAction(onSuccess, onFailure, entitiesArray, parameters, 'lock');
        };

        this.genericAction({dialogComponentType: LockDeviceDialogComponent, serverCallback, dialogData});
    };

    syncDeviceLinks = (deviceSelection) => {
        const entitiesArray = deviceSelection.map((device) => ({
            id: device.id,
            regionId: device.regionId,
            productType: device.productType
        }));
        const deviceIdsStr = entitiesArray.map((device) => device.id).join(', ');
        const successMessage = 'Successfully started populate links of devices with ids: ' + deviceIdsStr;

        const dialogData: any = {
            ipGroup: true,
            trunkGroup: true,
            mediaRealm: true,
            actionName: 'sync',
            entitiesArray,
            successMessage
        };

        let isMediaDecompositionExist = false;
        const devicesProductType = entitiesArray.map((device) => device.productType);

        this.mediaDecompositionDevicesList.forEach((mediaDecompositionType) => {
            if (devicesProductType.includes(mediaDecompositionType)) {
                isMediaDecompositionExist = true;
            }
        });

        if (isMediaDecompositionExist) {
            dialogData.mediaServer = true;
        }

        const serverCallback = (onSuccess, onFailure) => this.devicesRestService.doRestAction(onSuccess, onFailure, entitiesArray, dialogData, 'syncLinks');

        this.genericAction({dialogComponentType: SyncLinksDialogComponent, serverCallback, dialogData});
    };

    startStopPolling = (deviceSelection, type) => {
        const entitiesArray = this.mapDeviceFn(deviceSelection);
        const successMessage = 'Success to ' + type.toLowerCase() + ' devices';
        const confirmAlternativeText = type.toLowerCase() + ' polling performance monitoring';

        const serverCallback = (onSuccess, onFailure) => {
            this.devicesRestService.doRestAction(onSuccess, onFailure, entitiesArray, undefined, type === 'Start' ? 'startPolling' : 'stopPolling');
        };

        const dialogData: ConfirmDialogData = {
            entitiesArray,
            successMessage,
            confirmAlternativeText,
            confirmForItem: true
        };

        this.genericConfirmAction({
            serverCallback,
            dialogData,
            dialogConfig: {title: type + ' Polling', submitButtonText: type}
        });
    };

    selectPmProfile = (devices) => {
        const parameters = {filter: {tenantId: devices[0].tenantId}, fields: ['id', 'name', 'attachedDevices']};

        const onSuccess = (response: RestResponseSuccess) => {
            this.openSelectPmProfileDialog(response.data.profiles, devices);
        };

        this.performanceMeasurementsService.getProfiles(onSuccess, () => null, parameters);
    };

    receiveFile = (deviceInfo, fileType?) => {
        fileType = fileType || this.getConfigFileTypeOptions(deviceInfo); // return array or string by device product type

        if (_.isString(fileType)) {
            this.devicesRestService.receiveFile(this.onSuccessReceiveFile(deviceInfo, fileType), this.onFailureReceiveFile(deviceInfo, fileType), deviceInfo.id, fileType);
        } else {
            this.chooseFileToExport(deviceInfo, fileType);
        }
    };

    openRdpSession = (device) => {
        this.devicesRestService.getRdpSessionURL(device.id).then((res: RestResponseSuccess) => {
            const url = this.deviceSSOActions.buildSSOUrl(res.data.url);
            window.open(url);
        });
    };

    doRestAction = (deviceSelection, actionName) => {
        const entitiesArray = this.mapDeviceFn(deviceSelection);
        const dialogData: ConfirmDialogData = {...this.actionRestMapper[actionName].dialogData, entitiesArray};

        const serverCallback = (onSuccess, onFailure) => {
            this.devicesRestService.doRestAction(onSuccess, onFailure, entitiesArray, undefined, this.actionRestMapper[actionName].type);
        };

        this.genericConfirmAction({serverCallback, dialogData});
    };

    fileExtensionMap = {INI_TYPE: 'ini', CONF_TYPE: 'conf', JSON_TYPE: 'json', VAIC_ZIP_TYPE: 'zip', SBC_ZIP_TYPE: 'tar.zip'};

    onSuccessReceiveFile = (deviceInfo, fileType) => (value) => {
        const typeOfFile = this.fileExtensionMap[fileType] || 'cli';

        const fileName = [
            deviceInfo.ipAddress,
            (deviceInfo.sbcInfo && deviceInfo.sbcInfo.serialNum) || '',
            deviceInfo.productType,
            dateTimeFormatter(Date.now(), FormatterType.dateAndTime)
        ].join('_').split(' ').join('_') + '.' + typeOfFile;

        if (typeOfFile.includes('zip')) {
            this.saveDataToFileService.createZIPDataFile(fileName, value.data);
        } else {
            this.FileSaver.saveAs(new Blob([value.data]), fileName);
        }

        this.logger.info('File of type: ' + fileType + ' of device: ' + deviceInfo.id + ' saved successfully');
    };

    private getConfigFileTypeOptions = (device) => {
        const productType = device.productType;
        const isZipSupported = device?.supportedFeatures?.files?.zip;

        if (['MP500_MSBG', 'MEDIANT_1000_MSBG', 'MEDIANT_500_MSBG', 'MEDIANT_850_MSBG',
            'MEDIANT_500L_MSBR', 'MEDIANT_500LI_MSBR', 'MEDIANT_800CI_MSBR', 'MEDIANT_800B_MSBR', 'MEDIANT_800C_MSBR'].includes(productType)) {
            return isZipSupported ? ['CLI_SCRIPT_TYPE', 'INI_TYPE', 'SBC_ZIP_TYPE'] : ['CLI_SCRIPT_TYPE', 'INI_TYPE'];
        } else if (['MP202B', 'MP202D', 'MP202R', 'MP204B', 'MP204D', 'MP204R'].includes(productType)) {
            return 'CONF_TYPE';
        } else if (['STACK_MANAGER'].includes(productType)) {
            return 'JSON_TYPE';
        } else if (['VAIC'].includes(productType)) {
            return 'VAIC_ZIP_TYPE';
        }

        return isZipSupported ? ['INI_TYPE', 'SBC_ZIP_TYPE'] : 'INI_TYPE';
    };

    private onFailureReceiveFile = (deviceInfo, fileType) => () => {
        this.logger.error('Failed to save file of type: ' + fileType + ' of device: ' + deviceInfo.id);
    };

    private chooseFileToExport = (deviceInfo, fileTypes) => {
        const message = 'Choose type of config file to export:';
        const options = {
            radioButtonsModel: {
                value: 'INI_TYPE',
                items: [{text: 'INI', value: 'INI_TYPE'}]
            }
        };

        if (fileTypes.includes('CLI_SCRIPT_TYPE')) {
            options.radioButtonsModel.items.push({text: 'CLI', value: 'CLI_SCRIPT_TYPE'});
        }

        if (fileTypes.includes('SBC_ZIP_TYPE')) {
            options.radioButtonsModel.items.push({text: 'ZIP', value: 'SBC_ZIP_TYPE'});
        }

        const dialogData: ConfirmDialogData = {message, options};
        const serverCallback = (success, failure, dialogConfigRef) => {
            const responseOptions = dialogConfigRef.dialogData.options;
            const selection = (responseOptions.radioButtonsModel && responseOptions.radioButtonsModel.value);

            const onSuccessReceiveFile = this.onSuccessReceiveFile(deviceInfo, selection);
            success();

            if (selection) {
                this.devicesRestService.receiveFile(onSuccessReceiveFile, failure, deviceInfo.id, selection);
            }
        };

        this.genericConfirmAction({
            serverCallback,
            dialogData,
            dialogConfig: {title: 'Export Configuration To File', submitButtonText: 'Export'}
        });
    };

    private openSelectPmProfileDialog = (profiles, devices) => {
        const selectedProfile = _.cloneDeep(this.generateSelectedProfileObject(devices));

        const devicesIds = devices.map((device) => device.id);
        const successMessage = 'Devices with ids ' + devicesIds.join(', ') + ' added successfully to a pm profile with id ' + selectedProfile.value;

        const dialogData = {selectedProfile, profiles, actionName: 'selectPm', entitiesArray: devices, successMessage};
        const serverCallback = (onSuccess, onFailure) => {
            const selectedProfileObject = profiles.find((profileItem) => profileItem.id === selectedProfile.value);

            const newProfile = {
                attachedDevices: selectedProfileObject.attachedDevices.concat(devicesIds).filter((el, i, arr) => arr.indexOf(el) === i)
            };

            this.performanceMeasurementsService.edit(onSuccess, onFailure, newProfile, selectedProfile.value);
        };

        const dialogConfig = {
            id: 'select-pm-profile-dialog',
            title: 'Select PM Profile',
            cancelButtonText: 'Close',
            submitButtonText: 'Select',
            width: 440
        };

        this.genericAction({
            serverCallback,
            dialogData,
            dialogComponentType: SelectPmProfileDialogComponent,
            dialogConfig
        });
    };

    private generateSelectedProfileObject = (devices) => {
        if (Array.isArray(devices) && devices.length === 1) {
            const deviceProfile = devices[0] && devices[0].sbcInfo && devices[0].sbcInfo.pmInfo && devices[0].sbcInfo.pmInfo.profileId;
            return deviceProfile ? {value: deviceProfile} : {};
        }

        return {};
    };

    private getNecessaryDetailsFromDevices = (devices, isDownloadMode): deviceFileDetails => {
        const osVersionList = [];// check if devices are listed to one osVersion(CentOS6 or CentOS8)
        const mcTypeList = [];
        let isAllDevicesSWVersionAbove7_3 = true;
        let isAllDevicesInSBCSupportedList = true;
        let isAllDevicesHA = true;
        const clusterManagerRadioGroup = {value: 'WITHOUT_MTC'};

        if (!isDownloadMode) {
            devices.forEach((device) => {
                if (!device.osVersion && !osVersionList.includes('OS6')) {
                    osVersionList.push('OS6'); // Unknown osVersion is classified as CentOS6
                } else if (device.osVersion && !osVersionList.includes(device.osVersion)) {
                    osVersionList.push(device.osVersion);
                }

                if (!device.isHA) {
                    isAllDevicesHA = false;
                }

                if ((!device.mcType || (device.mcType && device.mcType !== 'VMC' && device.mcType !== 'MT')) && !mcTypeList.includes('unknown')) {
                    mcTypeList.push('unknown');
                } else if (!mcTypeList.includes(device.mcType)) {
                    mcTypeList.push(device.mcType);
                }

                if (!this.supportedTypes.VMT_SUPPORTED_PRODUCTS_TYPES.includes(device.productType)) {
                    isAllDevicesInSBCSupportedList = false;
                }

                const swVersionFloat = parseFloat(device.swVersion.substring(0, 3));
                if (swVersionFloat < 7.3) {
                    isAllDevicesSWVersionAbove7_3 = false;
                }
            });
        }

        return {
            osVersionList,
            mcTypeList,
            isAllDevicesSWVersionAbove7_3,
            clusterManagerRadioGroup,
            isAllDevicesInSBCSupportedList,
            isAllDevicesHA
        };
    };

    private getSoftwareFileList = (mainSuccess, mainFailure, parameters) => {
        const onSuccess = (response: RestResponseSuccess) => {
            mainSuccess(response.data, 'softwareFiles');
        };

        const onFailure = (error: RestResponseFailure) => {
            mainFailure(error, 'softwareFiles');
        };

        this.softwareManagerRestService.getFiles(onSuccess, onFailure, parameters || {});
    };

    private getGracefulTimeout = (device) => {
        switch (device.deviceConfigs.lockBy) {
            case 'immediateLock'          :
                return 0;
            case 'gracefulLock'           :
                return -1;
            case 'gracefulLockWithSeconds':
                return device.lockBySeconds;
        }
    };

    private mapDeviceForUpgradeFn = (devices) => {
        return devices.map((device) => {
            let osVersion;
            let mcType = '';
            let isHA = false;
            if (device.sbcInfo) {
                if (device.sbcInfo.osVersion && device.sbcInfo && device.sbcInfo.osVersion !== '') {
                    osVersion = device.sbcInfo.osVersion;
                }

                if (device.sbcInfo.mcType) {
                    mcType = device.sbcInfo.mcType;
                }

                if (device.sbcInfo.isHA) {
                    isHA = true;
                }
            }

            return {
                id: device.id,
                tenantId: device.tenantId,
                regionId: device.regionId,
                productType: device.productType,
                swVersion: device.swVersion,
                osVersion,
                mcType,
                isHA
            };
        });
    };

    private openSoftwareUpgradeDialog = (tenants, files, entitiesArray, isDownloadMode, deviceDetails) => {
        const filesWithSoftwareType = files && files.length > 0 ? this.handleFilesBeforeDialogOpening(files, deviceDetails.osVersionList) : files;
        const iniOptions = isDownloadMode ? {forwardType: 'FULL_WITH_VALIDATION'} : {
            hitless: undefined,
            gracefulTimeout: undefined
        };

        const dialogData = {
            selectedFile: [],
            entitiesArray,
            deviceDetails,
            filesWithSoftwareType,
            tenants,
            isDownloadMode,
            iniOptions,
            actionName: 'upgrade',
            TLS: {allSelectionTLS: false, TLSContexts: [], TLSContextSelected: undefined}
        };

        const serverCallback = (onSuccess, onFailure) => this.handleSoftwareUpgradeConfirm(dialogData, entitiesArray, onSuccess, onFailure);

        const dialogConfig = {
            submitButtonText: 'Update',
            cancelButtonText: 'Close',
            id: 'software-upgrade-dialog',
            width: 558,
        };

        this.genericAction({
            serverCallback,
            dialogData,
            dialogComponentType: SoftwareUpgradeDialogComponent,
            dialogConfig
        });
    };

    private handleSoftwareUpgradeConfirm = (dialogData, devices, onSuccess, onFailure) => {
        const parameters: any = {fileType: dialogData.selectedFile[0].fileType, file: dialogData.selectedFile[0].id};
        if (dialogData.TLS.allSelectionTLS) {
            if (dialogData.TLS.TLSContextSelected > 0) {
                parameters.tlsContextIdx = parseInt(dialogData.TLS.TLSContextSelected, 10);
            }
        } else if (dialogData.isDownloadMode && dialogData.selectedFile[0].fileType === 'INI_STAND_ALONE_TYPE') {
            parameters.incremental = dialogData.iniOptions.forwardType;
        } else if (!dialogData.isDownloadMode && dialogData.deviceDetails.isAllDevicesSWVersionAbove7_3 && dialogData.deviceDetails.isAllDevicesInSBCSupportedList
            && dialogData.deviceDetails.clusterManagerRadioGroup.value === 'WITH_MTC') {
            const mcParameters: any = {fileId: dialogData.selectedFile[0].id};
            if (dialogData.iniOptions.hitless !== undefined) {
                mcParameters.hitless = dialogData.iniOptions.hitless;
            }

            if (dialogData.iniOptions.gracefulTimeout !== undefined) {
                mcParameters.gracefulTimeout = dialogData.iniOptions.gracefulTimeout;
            }

            this.devicesRestService.doRestAction(onSuccess, onFailure, devices, mcParameters, 'upgradeMC');
            return;
        } else if (!dialogData.isDownloadMode && dialogData.deviceDetails.isAllDevicesHA && dialogData.selectedFile[0].fileType === 'CMP_TYPE' && dialogData.iniOptions.hitless !== undefined) {
            parameters.hitless = dialogData.iniOptions.hitless;
        }

        this.devicesRestService.doRestAction(onSuccess, onFailure, devices, parameters, 'sendFile');
    };

    private handleFilesBeforeDialogOpening = (files, osVersionList) => {
        const DOWNLOADABLE = ['CMP_TYPE', 'RMT_TYPE', 'RMS_TYPE'];

        return files.filter((file) => {
            file.swType = DOWNLOADABLE.includes(file.fileType) ? 'Downloadable' : 'Auxiliary';

            const isFileTypeAllowed = (file.fileType !== 'EMS_TYPE') && (file.fileType !== 'VER_EMS_TYPE');

            if (file.fileType !== 'CMP_TYPE') {
                return isFileTypeAllowed;
            }

            return osVersionList.length === 0 || (osVersionList.length === 1 && (!file.osVersion || file.osVersion === osVersionList[0]));
        });
    };

    private mapDeviceFn = (devices) => devices.map((device) => ({
        id: device.id,
        regionId: device.regionId,
        name: device.name,
        productType: device.productType
    }));

    downloadTopology = () => {
        const onSuccess = (value) => {
            const fileType = {type: 'octet/stream'};
            const blob = new Blob([value.data], fileType);
            this.FileSaver.saveAs(blob, 'topology.csv');
        };

        this.devicesRestService.download(
            onSuccess,
            () => {},
            undefined,
            'topology/actions/uploadTopology');
    };
}
