import {Rest, RestResponseSuccess} from './rest';
import * as _ from 'lodash';

import {ServiceInjector} from '../services/service-injector';
import {NetworkFilterFormatterService} from '../../network/services/network-filter-formatter.service';

export class NetworkRest extends Rest {

    protected url: string;
    protected entityType: any;

    private networkFilterFormatter = ServiceInjector.injector.get(NetworkFilterFormatterService);

    constructor({
        url = undefined,
        entityFilterFormatter = undefined,
        entityType = undefined,
        urlToFilterCallingApi = undefined,
        addSearchToParams  = undefined,
        filterEventName  = undefined
    }) {
        super({url, entityFilterFormatter, entityType, urlToFilterCallingApi, addSearchToParams, filterEventName});
        this.url = url;
        this.entityType = entityType;
    }

    setURL = (url) => {
        this.url = url;
    };

    getFilteredEntitiesForTable = (parameters, success, failure, filteredEntity?, url?) => {
        const onSuccess = (response) => {
            if(response.status === 200){
                response.data && response.data[this.entityType] && response.data[this.entityType].forEach((entity, idx)=>{
                    const wsEntity = _.cloneDeep(this.wsEntities.getEntity(this.entityType, entity.id));
                    response.data[this.entityType][idx] = _.extend(wsEntity, entity);
                });
            }
            success(response);
        };
        this.get({url, parameters, extendParameters: true, filteredEntity}).then(onSuccess).catch(failure);
    };

    getEntitiesWithFilter = (success, failure, parameters) => {
        this.get({parameters, extendParameters: true, skipPopulateFilter: true}).then(success).catch(failure);
    };

    // WS
    getAllEntities = (ids?) => this.wsEntities.getEntitiesArray(this.entityType, ids || null);

    getAllEntitiesHashed = () => this.wsEntities.getEntities(this.entityType);

    getFilterIds = () => this.wsEntities.getFilteredIds(this.entityType);

    getFilteredEntities = () => {
        const filteredIds = this.wsEntities.getFilteredIds(this.entityType);

        return this.getEntitiesByIds(filteredIds);
    };

    getEntitiesByIds = (listOfIds) => this.wsEntities.getEntitiesArray(this.entityType, listOfIds);

    getEntityById = (entityId) => this.wsEntities.getEntity(this.entityType, entityId);

    getFilteredEntitiesHashed = (ids?) => this.wsEntities.getFilteredEntitiesHashed(this.entityType, ids);

    // Statistics
    getMonitorStatisticsByUnitID = (success, failure, parameters?) => {
        this.get({parameters, url: 'callsMonitor/statistics/entities', extendParameters: true, requestAPI: 'entities', useCachedConnection: true}).then(success).catch(failure);
    };

    getMonitorStatisticsByFilter = (success, failure, parameters?) => {
        this.get({parameters, url: 'callsMonitor/statistics/' + this.entityType + 'Entities', extendParameters: true,
            requestAPI: 'entities', useCachedConnection: true}).then(success).catch(failure);
    };

    getTopologyStatistics = (success, failure, parameters?, entityType?, filteredEntity?) => {
        this.get({parameters, url: 'topology/statistics/' + (entityType || this.entityType),extendParameters: true, filteredEntity,
            requestAPI: 'entities', useCachedConnection: true}).then(success).catch(failure);
    };

    getEntitiesStatisticsForDynamicTab = (success, failure, parameters) => {
        this.get({parameters, url: 'callsMonitor/statistics/entities', extendParameters: true, requestAPI: 'entities',
            useCachedConnection: true, skipPopulateFilter: true}).then(success).catch(failure);
    };

    public getActiveAlarmsSeveritySummaryForNetwork = (success, failure, parameters, isSite) => {//  OVOC-2877
        const onSuccess = (response: RestResponseSuccess) => {
            success(response.status === 204 ? {data: {}} : response);
        };

        this.get({parameters, url :'alarms/statistics/active/severitySum',
            filteredEntity: isSite ? 'siteAlarmsSummary' : 'networkAlarmsSummary', build204JSON: false}).then(onSuccess).catch(failure);
    };

    // LockedURL
    getLockedURL = (uri = undefined) => (ids, requestFiltered, fields?) => {
        const parameters = this.createParams(ids, requestFiltered, fields);
        const query: any = this.buildURLs.restructureParamsObject(parameters);

        if (!query.filter && requestFiltered) {
            return null;
        }

        return this.addFilterToURL(parameters, uri);
    };

    createParams(ids: any, requestFiltered: any, fields?: any) {
        const parameters: any = {detail: 1, limit: 100000};
        if (fields) {
            parameters.fields = fields;
        }
        const timeFilter: any = this.networkFilterFormatter.getTimeFilter(this.networkFilterFormatter.getAggregatedFilter('NetworkFilter'));

        if (timeFilter !== null && this.entityType !== 'channels') {
            parameters.filter = parameters.filter || {};
            parameters.filter = timeFilter;
        }

        if (ids) {
            parameters.filter = parameters.filter || {};
            if(this.entityType === 'groups' ){
                parameters.filter.id = ids;
            }else{
                parameters.filter.specificId = ids;
            }
        }

        if (requestFiltered && this.entityType !== 'tenants' && this.entityType !== 'regions') {
            parameters.fields = ['id'];
            this.populateFilterPropertyIfNeeded(parameters, this.entityType);
        }
        return parameters;
    }
}
