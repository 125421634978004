<div class="flexColumnParent">
    <div class="flexItem content-top">
        <ng-template [ngTemplateOutlet]="dialogConfig.dialogData?.logoTemplate"></ng-template>
    </div>
    <div class="flexItem content-right">
        <div>
            <div>VERSION {{generalService.serverInfo?.ovocVersion}}</div>
            <div *ngIf="generalService.serverInfo.systemType==='OVOC'">
                <div>© 2003-{{currentYear}} AudioCodes Limited. All rights reserved. Trademarks and SEC Notice of
                    AudioCodes Limited.
                </div>
                <div></div>
                <a src="http://www.audiocodes.com"></a>
            </div>
        </div>
    </div>

    <div class="flexItem audiocodes-logo-container" *ngIf="generalService.serverInfo?.branding === null || generalService.serverInfo?.branding === 'LiveExpress'">
        <ac-svg name="audiocodes_logo"></ac-svg>
    </div>
</div>
