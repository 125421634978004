import {Component, EventEmitter, Host, Input, Optional, Output} from '@angular/core';
import {WSEntities} from 'ac-infra';
import {NetworkGroupsFilterComponent} from '../network-groups-filter/network-groups-filter.component';

@Component({
    selector: 'network-groups-selection',
    styleUrls: ['./network-groups-selection.component.less'],
    templateUrl: './network-groups-selection.component.html',
})
export class NetworkGroupsSelectionComponent {

    @Input() isMultiple = true;
    @Input() isVisible = true;
    @Input() ngDisabled = false;
    @Input() sortItems = false;
    @Input() model: any;
    @Input() items: any;
    @Input() alternativeName: string;
    @Input() label: string;
    @Output() acChanged = new EventEmitter<any>();

    itemsInitializeInternally = false;

    _type: 'TOPOLOGY' | 'ENDPOINT' | 'CUSTOMER';

    @Input() set type(type) {
        this._type = type;
        this.setTitle();

        if (this.itemsInitializeInternally) {
            this.getItems();
        }
    }

    constructor(private wsEntities: WSEntities,
                @Host() @Optional() public networkGroupsFilterComponent: NetworkGroupsFilterComponent) {
    }

    ngOnInit() {
        !this.label && this.setTitle();

        if (this.items === undefined) {
            this.itemsInitializeInternally = true;
            this.getItems();
        }
    }

    getItems = () => {
        this.items = this.wsEntities.getEntitiesArray(this._type === 'CUSTOMER' ? 'customers' : 'groups').filter((group) => group.type === this._type);
    };

    setTitle = () => {
        let label = this._type.toLowerCase();

        if(this._type !== 'CUSTOMER'){
            label += ' Group';
        }

        this.label =  label + (this.isMultiple ? 's' : '');
    };
}
