import {Injectable} from '@angular/core';
import {AcDialogRef, LoggerService, PromiseService} from 'ac-infra';
import * as _ from 'lodash';
import {TenantsRestService} from '../apis/tenants-rest.service';
import {LicenseRestService} from '../apis/license-rest.service';
import {TemplatesRestService} from '../../../system/configuration/configuration-api/templates-rest.service';
import {NetworkTenantDialogComponent} from '../../dialogs/network-tenant-dialog/network-tenant-dialog.component';
import {RestResponseFailure, RestResponseSuccess} from '../../../common/server-actions/rest';
import {MessagesService} from '../../../common/utilities/messages.service';
import {OperatorsRestService} from '../../../system/administration/administration-api/operators-rest.service';
import {Actions} from '../../../common/server-actions/actions';
import {NetworkOperatorConnectDialogComponent} from '../../dialogs/network-operator-connect-dialog/network-operator-connect-dialog.component';
import {SaveDataToFileService} from '../../../common/utilities/save-data-to-file.service';
import {GroupsActions} from './groups-actions.service';

@Injectable({providedIn: 'root'})
export class TenantsActionsService extends Actions {

    dataObject;

    constructor(public loggerService: LoggerService,
                public messagesService: MessagesService,
                private tenantsRestService: TenantsRestService,
                private operatorsRestService: OperatorsRestService,
                private licenseRestService: LicenseRestService,
                private saveDataToFileService: SaveDataToFileService,
                private groupsActions: GroupsActions,
                private templatesRestService: TemplatesRestService) {
        super({entityName: 'tenant', entityService: tenantsRestService, isWsEntity: true});
    }

    addTenant = () => {
        const dialogRef = this.acDialogService.open();
        this.getDialogOptionListsData(false, dialogRef, {});
    };

    editTenant = (tenantId) => {
        const dialogRef = this.acDialogService.open();
        const success = (response: RestResponseSuccess) => {
            this.getDialogOptionListsData(true, dialogRef, response.data);
        }
        this.tenantsRestService.getById({success, extendParameters: true, skipPopulateFilter: true, id: tenantId});
    };

    deleteTenant = (tenant) => {
        const confirmMessage = this.messagesService.getConfirmMessage({
            entityName: 'tenant', entitiesArray: [tenant],
            messagePostfix: '<br><br><b class="warning-note">Note: deleting tenant might have an impact on its devices traffic!<b>'
        });
        this.delete({entityArray: [tenant], confirmMessage, isDeleteSingle: true});
    };

    editOperatorConnect = (spAttachedTenant) => {
        const operatorConnect = {operatorConnectInfo: _.cloneDeep(spAttachedTenant.operatorConnectInfo)};
        operatorConnect.operatorConnectInfo.appRegistrationClientId = 'fake->only for schema - will not be sent to server';
        operatorConnect.operatorConnectInfo.appRegistrationClientSecret = 'fake->only for schema - will not be sent to server';

        const dialogData = {entity: operatorConnect, spAttachedTenant};

        const serverCallback = (onSuccess, onFailure) => {
            const operatorConnectToServer = {offers: dialogData.entity.operatorConnectInfo.offers, operatorContacts: dialogData.entity.operatorConnectInfo.operatorContacts};
            this.tenantsRestService.put(onSuccess, onFailure, operatorConnectToServer, 'topology/operatorConnect/' + spAttachedTenant.id);
        };
        const dialogComponentType = NetworkOperatorConnectDialogComponent;
        const dialogConfig = {
            title: 'Operator Connect Details',
            width: 800,
            height: 720,
            id: 'operator-connect-dialog',
            cancelButtonText: 'Close',
        };

        this.genericAction({serverCallback, dialogData, dialogComponentType, dialogConfig});
    };

    downloadOperatorConnectJSON = (tenant) => {
        this.saveDataToFileService.createJsonDataFile(tenant.generatedJson, 'operator-connect-' + tenant.id);
    };

    private getDialogOptionListsData = (isEdit, dialogRef, tenant?) => {
        this.dataObject = {};
        const initPromises = [
            this.getData('license', (onSuccess, onFailure) => {
                this.licenseRestService.getLicense(onSuccess, onFailure);
            }),
            this.getData('operatorsObj', (onSuccess, onFailure) => {
                this.operatorsRestService.getOperators(onSuccess, onFailure);
            })
        ];

        if (isEdit) {
            const licenseAllocationPromise = this.getData('licenseAllocations',
                (onSuccess, onFailure) => {
                    this.licenseRestService.getLicenseAllocationById(onSuccess, onFailure, tenant.id);
                });
            initPromises.push(licenseAllocationPromise);
        } else {
            this.dataObject.tenants = this.tenantsRestService.getAllEntities();
            const httpPromise = this.getData('httpProfile',
                (onSuccess, onFailure) => {
                    this.templatesRestService.getHttpTemplate(onSuccess, onFailure);
                });
            const snmpPromise = this.getData('snmpProfile',
                (onSuccess, onFailure) => {
                    this.templatesRestService.getSnmpTemplate(onSuccess, onFailure);
                });
            initPromises.push(httpPromise, snmpPromise);
        }

        Promise.all(initPromises).then(() => {
            this.openTenantDialog(this.dataObject, isEdit, tenant, dialogRef);
        }).catch(() => dialogRef.close());
    };

    private openTenantDialog = (dataObject, isEdit, tenant, dialogRef: AcDialogRef) => {
        const tenantLicenseAllocation = {
            licensePoolFeatures: {
                totalCBAnalogDevices: 0,
                totalCBPbxUsers: 0,
                totalCBUsers: 0,
                totalCBVoicemailAccounts: 0,
                totalDevices: 0,
                totalSBCRegistrations: 0,
                totalSBCSessions: 0,
                totalSBCSignaling: 0,
                totalSBCTranscoding: 0
            },
            voiceQualityFeatures: {
                totalDevices: 0,
                totalEndpoints: 0,
                totalSBCSessions: 0,
                totalUsers: 0,
                totalReports: 0
            },
            endpointsFeatures: {totalEndpoints: 0}
        };
        dataObject.licenseAllocations = dataObject.licenseAllocations ? dataObject.licenseAllocations : tenantLicenseAllocation;

        const tenantToSend = isEdit ? _.cloneDeep(tenant) : this.prepareNewTenantProperties(dataObject);
        if (isEdit) {
            this.cleanEmptyValues(tenantToSend);
        }
        const id = tenantToSend.id;

        const onSuccessCallback = (response) => {
            this.updateLicenseAllocations(id || response.data.id, dataObject.licenseAllocations);
        };
        const dialogData = {
            entity: tenantToSend,
            licenseObj: dataObject.license,
            operators: dataObject.operatorsObj.operators,
            licenseAllocations: dataObject.licenseAllocations,
            isEdit,
            onSuccessCallback
        };
        const serverCallback = (onSuccess, onFailure) => {
            this.removeUnnecessaryFields(tenantToSend);
            if (isEdit) {
                this.tenantsRestService.edit(onSuccess, onFailure, tenantToSend, id);
            } else {
                this.tenantsRestService.add(onSuccess, onFailure, tenantToSend);
            }
        };

        this.genericAction({serverCallback, dialogData, dialogRef, dialogComponentType: NetworkTenantDialogComponent});
    };

    private prepareNewTenantProperties = (dataObject) => ({
        snmpProfile: dataObject.snmpProfile,
        uriRegExp: '*',
        httpProfile: dataObject.httpProfile,
        tenantAddressSpace: {subnetMasks: []},
        isDefault: dataObject.tenants.length === 0,
        licensePoolOperatorId: -1,
        appRegistrationSettings: {urlMode: 'organizations'}
    });

    private getData = (objectName, callback) => {
        const defer = PromiseService.defer();

        const onSuccess = (response: RestResponseSuccess) => {
            this.dataObject[objectName] = response.data;
            defer.resolve();
        };
        const onFailure = () => {
            this.logger.error('failed to fetch ' + objectName + 'tenants data for dialog');
            defer.reject();
        };

        callback(onSuccess, onFailure);

        return defer.promise;
    };

    private updateLicenseAllocations = (tenantId, licenseAllocations) => {
        const success = () => {
            this.logger.info('success to update licenseAllocations for tenant with id: ' + tenantId);
        };
        const failure = (error: RestResponseFailure) => {
            this.logger.error(error.error);
        };
        const requestObject = {
            id: tenantId,
            licensePoolFeatures: licenseAllocations.licensePoolFeatures ? licenseAllocations.licensePoolFeatures : {},
            voiceQualityFeatures: licenseAllocations.voiceQualityFeatures ? licenseAllocations.voiceQualityFeatures : {},
            endpointsFeatures: licenseAllocations.endpointsFeatures ? licenseAllocations.endpointsFeatures : {}
        };

        this.licenseRestService.edit(success, failure, requestObject, tenantId);
    };

    private removeUnnecessaryFields(tenantToSend: any) {
        if (tenantToSend?.snmpProfile?.snmpV3DefaultProfile?.authProtocol === 'NO_AUTH_PROTOCOL') {
            delete tenantToSend.snmpProfile.snmpV3DefaultProfile.authPassword;
        }
        if (tenantToSend?.snmpProfile?.snmpV3DefaultProfile?.privProtocol === 'NO_PRIV_PROTOCOL') {
            delete tenantToSend.snmpProfile.snmpV3DefaultProfile.privPassword;
        }
    }
}
