import {Injectable} from '@angular/core';
import {NetworkFilterFormatterService} from '../network-filter-formatter.service';
import {NetworkRest} from '../../../common/server-actions/network-rest';

@Injectable({providedIn: 'root'})
export class EndpointsRestService extends NetworkRest {

    constructor(networkFilterFormatter: NetworkFilterFormatterService) {
        super({
            url: 'topology/endpoints', entityType: 'endpoints', entityFilterFormatter: networkFilterFormatter,
            filterEventName: 'NetworkFilter', addSearchToParams: true,
            urlToFilterCallingApi: {
                'topology/endpoints': 'endpoints',
                'callsMonitor/statistics/entities': 'endpointsMonitorStatistics',
                'callsMonitor/statistics/endpointsEntities': 'endpointsCallsStatistics',
                'topology/statistics/endpoints': 'endpointsTopologyStatistics'
            }
        });
    }

    public clearData = () => {
        this.cachedConnection.forceRefreshLockedUrl('endpoints');
    };

    public getAllEndpoints = (success, failure) => {
        const parameters = this.createParams(undefined, undefined, ['id', 'name', 'status', 'siteId', 'tenantId', 'regionId', 'deviceType']);
        this.get({parameters, skipPopulateFilter: true}).then(success).catch(failure);
    };

    public getFilteredEndpoints = (parameters, success, failure, skipPopulateFilter = true) => {
        this.get({parameters, useCachedConnection: true, skipPopulateFilter}).then(success).catch(failure);
    };

    public getEndPointsByIds = (success, failure, listOfIds, fields?) => {
        this.get({
            parameters: {filter: {id: listOfIds}, fields},
            skipPopulateFilter: true,
        }).then(success).catch(failure);
    };

    public getEndPointById = ({success, failure=undefined, endpointId}) => {
        this.getById({success, failure, id: endpointId, skipPopulateFilter: true});
    };

    public removeFromQoeMonitoring = (success, failure, selectedEndpoints) => {
        const endpoints = selectedEndpoints.map((item) => item.siteId + '/' + item.id);
        this.put(success, failure, {endpoints}, 'topology/actions/disableVQSupport');
    };
}
