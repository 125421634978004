import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';

import * as _ from 'lodash';

import {EncryptionService} from './encryption.service';
import {WebSocketService} from './ws/ws.service';
import {MetadataService} from '../../metadata/metadata.service';
import {ReportRestService} from '../../statistics/services/report-rest.service';
import {ReportEntitiesService} from '../../statistics/reports/services/report-entities.service';
import {ReportActionsService} from '../../statistics/actions/report-actions.service';
import {TenantsRestService} from '../../network/services/apis/tenants-rest.service';
import {RegionsRestService} from '../../network/services/apis/regions-rest.service';
import {
    ExternalApplicationsRestService
} from '../../system/configuration/configuration-api/external-applications-rest.service';
import {DevicesRestService} from '../../network/services/apis/devices-rest.service';
import {LinksRestService} from '../../network/services/apis/links-rest.service';
import {SitesRestService} from '../../network/services/apis/sites-rest.service';
import {ConstantStringsService} from '../utilities/constant-strings.service';
import {authGroup, SessionHelperService} from '../utilities/session-helper.service';
import {
    ExternalApplicationsActionsService
} from '../../system/configuration/configuration-actions/external-applications-actions.service';
import {WebSocketNotification} from './ws/ws.notification.service';
import {ServerDownDialogComponent} from '../../dialogs/server-down-dialog/server-down-dialog.component';
import {GroupsRestService} from '../../network/services/apis/groups-rest.service';
import {WorkspaceManagerService} from '../../workspace/workspace-manager.service';

import {LogoDisplayService} from './logo-display.service';
import {LogoSelectionService} from '../components/logo-selection/logo-selection.service';
import {
    AC_PROGRESS_LOADER_PATH,
    AcDialogRef,
    AcDialogService,
    AuthorizationService,
    AutoRefreshService,
    BuildUrlsService,
    CachedConnection,
    ConnectionService,
    ExceptionService,
    GeneralService,
    PromiseService,
    RestServerUrlService,
    SessionService,
    SessionStorageService,
    WSEntities
} from 'ac-infra';
import {BehaviorSubject} from 'rxjs';
import {ChannelsRestService} from '../../network/services/apis/channels-rest.service';


@Injectable({
    providedIn: 'root'
})
export class InitService {
    MFAInProgress = false;
    private serverDownDialogRef: AcDialogRef;
    private retrying;

    constructor(private buildURLsService: BuildUrlsService,
                private connection: ConnectionService,
                private encryptionService: EncryptionService,
                private webSocketService: WebSocketService,
                private exceptionService: ExceptionService,
                private sessionService: SessionService,
                private reportRestService: ReportRestService,
                private reportEntitiesService: ReportEntitiesService,
                private reportActionsService: ReportActionsService,
                private externalApplicationsActionsService: ExternalApplicationsActionsService,
                private cachedConnection: CachedConnection,
                private router: Router,
                private tenantsRestService: TenantsRestService,
                private regionsRestService: RegionsRestService,
                private autoRefreshService: AutoRefreshService,
                private webSocketNotification: WebSocketNotification,
                private externalApplicationsRestService: ExternalApplicationsRestService,
                private devicesRestService: DevicesRestService,
                private sitesRestService: SitesRestService,
                private groupsRestService: GroupsRestService,
                private channelsRestService: ChannelsRestService,
                private linksRestService: LinksRestService,
                private generalService: GeneralService,
                private restServerURLService: RestServerUrlService,
                private logoDisplayService: LogoDisplayService,
                private logoSelectionService: LogoSelectionService,
                private wsEntities: WSEntities,
                private acDialogService: AcDialogService,
                private titleService: Title,
                private workspaceManagerService: WorkspaceManagerService,
                @Inject(AC_PROGRESS_LOADER_PATH) private progressLoaderPath: BehaviorSubject<string>,
    ) {
        this.setLocalizeAcFormErrorObject();
    }

    setAuthorizationsGroups = () => {
        AuthorizationService.authGroups = SessionHelperService.authGroups;
    };

    updateSystemInfo = () => {
        // const url = this.restServerURLService.getRestServerURL() + 'status/rest';

        const success = (response) => {
            this.generalService.serverInfo = response;
            this.generalService.serverInfo.appVersion = response.ovocVersion;
            this.sessionService.setServerVersion(this.generalService.serverInfo.appVersion);

            this.generalService.serverInfo.oneLiveMode = response.ovocMode === 'OneLive';
            AuthorizationService.oneLiveMode = this.generalService.serverInfo.oneLiveMode;

            if (!response.branding) {
                this.progressLoaderPath.next('assets/animations/ovoc-loader.json');
            }

            this.generalService.serverInfo.systemType = response.branding || response.whiteLabeling?.name || (this.generalService.serverInfo.oneLiveMode ? 'AudioCodes Live' : 'OVOC');
            this.titleService.setTitle(this.generalService.serverInfo.systemType + ' - ' + location.hostname);

            if (this.generalService.serverInfo.branding === 'Nuera') {
                ExternalApplicationsActionsService.EXTERNAL_APPLICATION_OBJ.arm.disabledLink = 'https://www.nuera.com/';
            }

            if (this.generalService.serverInfo.oneLiveMode) {
                ConstantStringsService.linkOrCustomerProvider = 'Provider Side';
                ConstantStringsService.deviceOrCustomerTeams = 'Teams Side';
            }

            if (this.retrying) {
                this.acDialogService.closeDialog(this.serverDownDialogRef);
                this.serverDownDialogRef = null;
                this.router.navigateByUrl(this.router.url);
            }
            this.logoDisplayService.updateLogos();
        };

        // console.log(1);
        this.generalService.serverInfoPromise = (window as any).__ovoc.restResponse;
        this.generalService.serverInfoPromise.then(success).catch(this.serverFailure);
        return this.generalService.serverInfoPromise;
    };


    serverFailure = (error) => {
        if (this.serverDownDialogRef) {
            return;
        }
        this.serverDownDialogRef = this.acDialogService.open(ServerDownDialogComponent, {
            onClose: () => {
                clearTimeout(this.retrying);
                this.serverDownDialogRef = null;
                delete this.retrying;
                this.updateSystemInfo();
            },
            noCancelButton: true,
            preventEscape: true
        });

        this.retrying = setTimeout(() => {
            this.updateSystemInfo();
        }, 5000);
    };

    systemInit = (toState?, params?) => {
        const defer = PromiseService.defer();

        this.generalService.systemInitialized = false;

        Promise.all([
            this.generalService.serverInfoPromise,
            this.generalService.PDFMode || this.webSocketService.connect()
        ]).then(() => {
            this.generalService.finishedLoading('webSocket');
            this.init(defer);
        }, (err) => {
            defer.reject();
            if (err === 'ConnectionExist') {
                return;
            }
            const msg = err === 'expired' ? 'Session does not exist anymore' : 'The WebSocket server is down and could not perform the request';
            this.fireException(msg);
        });


        return defer.promise;
    };


    setLocalizeAcFormErrorObject = () => {
        const ovocLocalize = {
            en: require('../ajvErrors/ovoc-errors-en.js')
        };

        this.generalService.setLocalizeErrorsObject(ovocLocalize);
    };

    initLockedSystem() {
        this.cachedConnection.clearLockedUrls();
        return this.webSocketService.hasActiveSocket() ? true : this.webSocketService.connect();
    }

    private fireException = (message) => {
        this.sessionService.endSession();
        this.exceptionService.reportException({type: 'WSServerException', message});
    };

    private init = (defer) => {
        MetadataService.getMetadataTypes(this.buildURLsService, this.connection).then(() => {
            this.generalService.finishedLoading('metadata');
            this.generalService.isViewMode = AuthorizationService.isMonitor();

            Promise.all(
                [
                    this.reportActionsService.initMetrics(),
                    this.reportActionsService.initInfoColumns(),
                    this.generalService.PDFMode || this.initWSEntities()
                ]
            ).then(() => {
                if(this.generalService.PDFMode){
                    this.generalService.finishedLoading('filteredUpdate');
                    this.generalService.finishedLoading('workspace');
                    this.generalService.systemInitialized = true;
                    defer.resolve(true);
                }else{
                    this.initWorkspace().then(() => {
                        this.generalService.finishedLoading('workspace');
                        this.finalizeInit();
                        this.generalService.systemInitialized = true;
                        defer.resolve(true);
                    });
                }
            }, (reject) => {
                defer.reject();
                this.fireException('Failed to load resources');
            });
        });
    };

    private finalizeInit = () => {
        this.externalApplicationsActionsService.init();

        this.autoRefreshService.start();
        this.sessionService.restoreSession();
        this.setAppDefaults();
        this.webSocketNotification.init();
        this.cachedConnection.processPendingWSRequests();
        this.workspaceManagerService.initializeAutoSave();

        // this.sessionService.activeSession.mocks = mocks;
        if (this.sessionService.activeSession && this.sessionService.activeSession.mocks) {
            const mockEntities = this.sessionService.activeSession.mocks.entities;
            console.log('%c USING MOCK ENTITIES...', 'color: yellow');
            _.forOwn(mockEntities, (entities, entityType) => {
                this.wsEntities.setData(entityType, entities, 'id');
            });
        }
    };

    private initWSEntities = () => {
        const defer = PromiseService.defer();

        this.cachedConnection.updateCacheOptions({
            timeToLive: 30000
        });

        if (!AuthorizationService.validForMonitorLinkOrOneLiveTenantUserOrGroupUsers()) {
            this.cachedConnection.addLockedUrl({
                group: 'regions',
                ws: true,
                wsGeoMapFilteredOut: false,
                wsUrlBuilder: this.regionsRestService.getLockedURL()
            });

            this.cachedConnection.addLockedUrl({
                group: 'groups',
                ws: true,
                wsUrlBuilder: this.groupsRestService.getLockedURL('topology/groups'),
                wsGeoMapFilteredOut: false
            });
        } else {
            this.cachedConnection.clearLockedUrls();
        }

        if (this.generalService.serverInfo.oneLiveMode) {
            this.cachedConnection.addLockedUrl({
                group: 'customers',
                ws: true,
                wsUrlBuilder: this.groupsRestService.getLockedURL('topology/customers'),
                wsGeoMapFilteredOut: false
            });

            if (!AuthorizationService.validForEndCustomer()) {
                this.cachedConnection.addLockedUrl({
                    group: 'channels',
                    ws: true,
                    wsUrlBuilder: this.channelsRestService.getLockedURL(),
                    wsGeoMapFilteredOut: false
                });
            }
        }

        this.cachedConnection.addLockedUrl({
            group: 'tenants',
            ws: true,
            wsGeoMapFilteredOut: false,
            wsUrlBuilder: this.tenantsRestService.getLockedURL()
        });

        this.cachedConnection.addLockedUrl({
            group: 'devices',
            ws: true,
            wsUrlBuilder: this.devicesRestService.getLockedURL(),
            wsGeoMapFilteredOut: true
        });


        this.cachedConnection.addLockedUrl({
            group: 'links',
            ws: true,
            wsUrlBuilder: this.linksRestService.getLockedURL(),
            wsGeoMapFilteredOut: true
        });

        this.cachedConnection.addLockedUrl({
            group: 'sites',
            ws: true,
            wsUrlBuilder: this.sitesRestService.getLockedURL(),
            wsGeoMapFilteredOut: true
        });

        this.cachedConnection.fullSync(defer);
        return defer.promise;
    };

    private initWorkspace() {
        const workspaceLoadedDefer = PromiseService.defer();

        if (!this.sessionService.activeSession.workspaceLoaded) {
            this.sessionService.assignToSession({workspaceLoaded: true});
            this.workspaceManagerService.importWorkspace().then(() => {
                this.cachedConnection.filteredUpdate();
                workspaceLoadedDefer.resolve();
            });
        } else {
            this.workspaceManagerService.importWorkspace({loadToSessionStorage: false});
            workspaceLoadedDefer.resolve();
        }

        return workspaceLoadedDefer.promise;
    }

    private setAppDefaults() {
        const unpinnedAlarms = 'unpinnedAlarmsFilter';
        const defaultAlarms = SessionStorageService.getData(unpinnedAlarms) || {
            moreFilters_alarms: {
                sources: [],
                alarmNames: [],
                alarmsType: 'Only Alarms'
            }
        };
        SessionStorageService.setData(unpinnedAlarms, defaultAlarms);
    }
}
