import {Injectable} from '@angular/core';
import {LoggerService} from 'ac-infra';
import {Rest} from '../../../common/server-actions/rest';

@Injectable({providedIn: 'root'})
export class ServerConfigurationRestService extends Rest {


    constructor(public loggerService: LoggerService) {
        super({url: 'settings/server/general'});
    }

    public getServerConfiguration = (success = (...args) => null, failure = () => null) => {
        const onSuccess = (value) => {
            success(this.extractObject(value));
        };
        const promise = this.get({skipPopulateFilter: true, build204JSON: false});
        promise.then(onSuccess).catch(failure);
        return promise;
    };

    public editServerConfiguration = (serverConfiguration) => {
        const successMsg = 'Server configuration updated successfully';
        const onSuccess = (response) => {
            this.successfulUpdateAction(response.data.description || successMsg);
        };

        this.put(onSuccess, () => {
        }, serverConfiguration);
    };

    successfulUpdateAction = (message) => {
        this.acDialogService.info(message);
        this.loggerService.info(message);
    };
}
