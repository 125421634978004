import {Component, Input, TemplateRef} from '@angular/core';
import {GeneralService} from 'ac-infra';

@Component({
    selector: 'other-splash-logo',
    templateUrl: './other-splash-logo.component.html',
    styleUrls: ['./other-splash-logo.component.less'],
})
export class OtherSplashLogoComponent {
    constructor(public generalService: GeneralService) {}
}



