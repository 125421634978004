export enum statuses {
    Create = 'Create',
    Update = 'Update',
    Delete = 'Delete',
    Notifications = 'Notifications',
    FullSync = 'FullSync'
}

export class WSMessage {
    wsEntityTypeMapper = (entityType: string) => {
        if(!entityType){
            return ;
        }

        if(['arm', 'masterscope', 'zoompeering'].includes(entityType.toLowerCase())){
            return 'externalApplications';
        }

        return entityType.toLowerCase() + 's';
    }
    static readonly wsObjects = {
        Tenant: 'tenants',
        Region: 'regions',
        Site: 'sites',
        Device: 'devices',
        Link: 'links',
        Group: 'groups',
        Customer: 'customers',
        Channel: 'channels',
        // "Endpoint": "endpoints",
        // "LdapUser": 6,
        // "File": 20,
        // "AlertRule": 21,
        // "Alarm": 22,
        // "FwdRule": 23,
        // "VQThreshold": 24,
        // "ActiveDirectory": 25,
        // "Journal": 26,
        // "MgBackupFile": 27,
        // "PmProfile": 28,
        ARM: 'externalApplications',
        // "Call": 30,
        Masterscope: 'externalApplications',
        // "System": 100,
        // "NotRelevant": -1,

    };
    public name: string;
    public protocol: string;
    public description: string;
    public status: string;
    public messageType: statuses;
    public module: string;
    public arrivalTime: number;
    public entities: any[];
    public entitiesIds: any[];
    public entityType: string;
    public entityTypeName: string;

    constructor() {
        this.arrivalTime = Date.now();
    }

    prepareMessage = () => {
        this.entityTypeName = this.wsEntityTypeMapper(this.entityType);
        if (this.entities) {
            this.entitiesIds = this.entities.map((entity) => entity.entityId);
        }
    };
}
